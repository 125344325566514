import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class LoginService {
  private user = {
    username: '',
    role: '',
    login: false
  }

  setUsername(username) {
    this.user.username = username;
  }

  setRole(role) {
    this.user.role = role;
  }

  setLogin(login) {
    this.user.login = login
  }

  getUser() {
    return this.user;
  }

  createUser(username: string) {
    this.setUsername(username);
    this.setRole('admin');
    this.setLogin(true);
  }
}
